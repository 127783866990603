// TODO:

//1. text should remain in text boxes, create limits (failed - not critical)

//2. tab should say PDCA Pro and have a good logo, not React App (tab says PDCA Pro - no logo change yet)

//3. mobile version should look nice, not scrunched up

//4. input to change the title of the output file

//5. add images back using gpt
//6. change the colouring of the export to be dark black font and dark green font
//7. add watermark/footer saying "Created using PDCAPro.com by Leaf Tech"

//Features:
//When the site is generating the pdf, it should blur out the background and show a wheel "Creating PDCA"
//use firestore to track how many times it is used, and for how long - analytics
//login functionality

import React from "react";
import PDCAForm from "./PDCAForm";

const App = () => {
  return (
    <div
      id="app-content"
      style={{
        background: "linear-gradient(135deg, #DFFFD6, #E7FCE0)",
        padding: "20px",
        minHeight: "100vh",
      }}
    >
      <PDCAForm />
    </div>
  );
};

export default App;
