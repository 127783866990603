import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  Divider,
  Grid,
} from "@mui/material";

const PDCAForm = () => {
  const [plan, setPlan] = useState("");
  const [ifWe, setIfWe] = useState("");
  const [weWill, setWeWill] = useState("");
  const [doWhat, setDoWhat] = useState(["", "", "", "", "", "", "", ""]);
  const [doWho, setDoWho] = useState(["", "", "", "", "", "", "", ""]);
  const [doWhen, setDoWhen] = useState(["", "", "", "", "", "", "", ""]);
  const [check, setCheck] = useState("");
  const [act, setAct] = useState("");

  const handleInputChange = (e, setter, index) => {
    const { value } = e.target;
    setter((prev) => {
      const newValues = [...prev];
      newValues[index] = value;
      return newValues;
    });
  };

  const generatePDF = async () => {
    const input1 = document.getElementById("pdf-content");
    const input2 = document.getElementById("pdf-content-2");

    // Function to temporarily set a fixed width for the content
    const setFixedWidth = (input, width) => {
      const originalWidth = input.style.width;
      input.style.width = width;
      return originalWidth;
    };

    // Function to temporarily remove placeholder text
    const removePlaceholders = (textFields) => {
      textFields.forEach((field) => {
        field.setAttribute("data-placeholder", field.placeholder);
        field.placeholder = "";
      });
    };

    // Function to restore placeholder text
    const restorePlaceholders = (textFields) => {
      textFields.forEach((field) => {
        field.placeholder = field.getAttribute("data-placeholder");
        field.removeAttribute("data-placeholder");
      });
    };

    // Function to capture the content of a given element without the text areas
    const captureContent = async (input, textFields, scale) => {
      // Temporarily hide the text area content and placeholders
      textFields.forEach((field) => {
        field.setAttribute("data-value", field.value); // Save value in data attribute
        field.value = ""; // Clear the value
      });
      removePlaceholders(textFields);

      // Capture the webpage without the text area content
      const canvas = await html2canvas(input, {
        scale: scale,
        useCORS: true,
        logging: true,
        backgroundColor: null,
      });

      // Restore the text area content and placeholders
      textFields.forEach((field) => {
        field.value = field.getAttribute("data-value"); // Restore value from data attribute
        field.removeAttribute("data-value");
      });
      restorePlaceholders(textFields);

      return canvas.toDataURL("image/png");
    };

    // Extract text values and their positions from text fields
    const extractTextValues = (container, textFields) => {
      const containerRect = container.getBoundingClientRect();
      return Array.from(textFields).map((field) => ({
        id: field.id,
        value: field.value.trim(),
        x: field.getBoundingClientRect().left - containerRect.left,
        y: field.getBoundingClientRect().top - containerRect.top,
      }));
    };

    const scale = 1.5; // Increase the scale to improve resolution
    const fixedWidth = "900px"; // Adjust to your desired desktop width

    // Ensure the content is always captured with the fixed width and scale
    const captureWithFixedWidth = async (input, textFields) => {
      const originalWidth = setFixedWidth(input, fixedWidth); // Set to desired desktop width
      const data = await captureContent(input, textFields, scale);
      input.style.width = originalWidth; // Restore original width
      return data;
    };

    // Capture the first part
    const textFields1 = input1.querySelectorAll("textarea, input");
    const firstPartImgData = await captureWithFixedWidth(input1, textFields1);
    const textValues1 = extractTextValues(input1, textFields1);

    // Capture the second part
    const textFields2 = input2.querySelectorAll("textarea, input");
    const secondPartImgData = await captureWithFixedWidth(input2, textFields2);
    const textValues2 = extractTextValues(input2, textFields2);

    // Gather text entries into an array
    const textEntries = [
      {
        id: "plan-field",
        value: document.getElementById("plan-field").value,
      },
      {
        id: "ifwe-field",
        value: document.getElementById("ifwe-field").value,
      },
      {
        id: "wewill-field",
        value: document.getElementById("wewill-field").value,
      },
      {
        id: "check-field",
        value: document.getElementById("check-field").value,
      },
      {
        id: "act-field",
        value: document.getElementById("act-field").value,
      },
      ...[0, 1, 2, 3, 4, 5, 6, 7].map((index) => ({
        id: `dowhat-field-${index}`,
        value: document.getElementById(`dowhat-field-${index}`).value,
      })),
      ...[0, 1, 2, 3, 4, 5, 6, 7].map((index) => ({
        id: `dowho-field-${index}`,
        value: document.getElementById(`dowho-field-${index}`).value,
      })),
      ...[0, 1, 2, 3, 4, 5, 6, 7].map((index) => ({
        id: `dowhen-field-${index}`,
        value: document.getElementById(`dowhen-field-${index}`).value,
      })),
    ];

    // Create the PDF
    const pdf = new jsPDF("p", "pt", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const margin = 20;
    const maxLineWidth = pdfWidth - margin * 2 - 60; // Adjust this value to wrap text sooner
    const lineHeight = 15; // Increase line height in points

    // Function to add text with wrapping
    const addTextWithWrapping = (text, x, y, fontSize = 10) => {
      pdf.setFontSize(fontSize);
      const lines = pdf.splitTextToSize(text, maxLineWidth);
      lines.forEach((line) => {
        if (y + lineHeight > pdfHeight - margin) {
          pdf.addPage();
          y = margin;
        }
        pdf.text(line, x, y);
        y += lineHeight;
      });
      return y;
    };

    // Add the first part to the PDF
    const imgPropsFirstPart = pdf.getImageProperties(firstPartImgData);
    const imgHeightFirstPart =
      (imgPropsFirstPart.height * pdfWidth) / imgPropsFirstPart.width;
    pdf.addImage(firstPartImgData, "PNG", 0, 0, pdfWidth, imgHeightFirstPart);

    const xBuffer = 16;
    // Add text boxes at specified positions on the first part
    const positions = [
      { id: "plan-field", x: margin + xBuffer, y: 150 }, // Adjust as needed
      { id: "ifwe-field", x: margin + xBuffer, y: 580 }, // Adjust as needed
      { id: "wewill-field", x: margin + xBuffer, y: 728 }, // Adjust as needed
    ];

    positions.forEach((pos) => {
      const entry = textEntries.find((e) => e.id === pos.id);
      if (entry && entry.value) {
        addTextWithWrapping(entry.value, pos.x, pos.y);
      }
    });

    // Add the second part to the PDF starting on a new page
    pdf.addPage();
    const imgPropsSecondPart = pdf.getImageProperties(secondPartImgData);
    const imgHeightSecondPart =
      (imgPropsSecondPart.height * pdfWidth) / imgPropsSecondPart.width;
    pdf.addImage(secondPartImgData, "PNG", 0, 0, pdfWidth, imgHeightSecondPart);

    const rowSpace = 25.8;
    const topRowY = 134;
    // Overlay the extracted text values from the second part
    const secondPagePositions = [
      { id: "check-field", x: margin + xBuffer, y: 428 }, // Adjust as needed
      { id: "act-field", x: margin + xBuffer, y: 678 }, // Adjust as needed
      ...[0, 1, 2, 3, 4, 5, 6, 7].map((index) => ({
        id: `dowhat-field-${index}`,
        x: margin + xBuffer - 2,
        y: topRowY + index * rowSpace, // Adjust as needed
      })),
      ...[0, 1, 2, 3, 4, 5, 6, 7].map((index) => ({
        id: `dowho-field-${index}`,
        x: pdfWidth - margin - 129,
        y: topRowY + index * rowSpace, // Adjust as needed
      })),
      ...[0, 1, 2, 3, 4, 5, 6, 7].map((index) => ({
        id: `dowhen-field-${index}`,
        x: pdfWidth - margin - 59,
        y: topRowY + index * rowSpace, // Adjust as needed
      })),
    ];

    secondPagePositions.forEach((pos) => {
      const entry = textEntries.find((e) => e.id === pos.id);
      if (entry && entry.value) {
        // Adjust font size for "what", "who", and "when" sections
        const isWhatWhoWhen =
          pos.id.startsWith("dowhat-field") ||
          pos.id.startsWith("dowho-field") ||
          pos.id.startsWith("dowhen-field");
        const fontSize = isWhatWhoWhen ? 10 : 12; // 2 points smaller for "what", "who", and "when"
        addTextWithWrapping(entry.value, pos.x, pos.y, fontSize);
      }
    });

    pdf.save("PDCA_Report.pdf");
  };

  // Call this function to generate the PDF
  //   generatePDF();

  return (
    <Container>
      <Container
        id="pdf-content"
        maxWidth="md"
        sx={{
          mt: 4,
          backgroundColor: "secondary.main",
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
          "@media (max-width:600px)": {
            maxWidth: "100%",
          },
        }}
      >
        <Box sx={{ padding: 2 }}>
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              textAlign: "center",
              color: "#a5d6a7", // Slightly lighter mint green
              fontWeight: "bold",
            }}
          >
            PDCA Form
          </Typography>

          <Box component="form">
            <Divider
              sx={{
                borderBottomWidth: 2,
                backgroundColor: "#66bb6a", // Mint green for divider
                marginBottom: 2,
              }}
            />
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                fontSize: "0.875rem",
                mb: 0,
                color: "#388e3c", // Darker mint green
              }}
            >
              Problem | Root Cause | Measurements | Hypothesis
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                fontSize: "0.875rem",
                mb: 0,
                color: "#388e3c", // Darker mint green
              }}
            >
              Communications/Influence | Consistent QV | Store Walk
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                fontSize: "0.875rem",
                mb: -3,
              }}
            >
              Define the issue or problem at hand. Ask yourself: "What are you
              trying to solve?" (CSA)
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "1.5rem", mb: 1 }}>
              Plan
            </Typography>
            <TextField
              id="plan-field"
              placeholder="Enter your plan details"
              variant="outlined"
              fullWidth
              multiline
              rows={23}
              value={plan}
              onChange={(e) => setPlan(e.target.value)}
              sx={{
                mb: 2,
                "& .MuiInputBase-input::placeholder": {
                  color: "#a5d6a7", // Light mint green for placeholder text
                },
                "& .MuiInputBase-input": {
                  color: "#212529",
                  fontSize: "1.125rem",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#66bb6a", // Mint green for focus
                  },
                },
              }}
            />
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "0.875rem", mb: 1 }}
            >
              Create and define your proposition on how you intend to solve the
              problem. Consider the root causes. (Hypothesis)
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "1.125rem", mb: 1 }}
            >
              If We:
            </Typography>
            <TextField
              id="ifwe-field"
              placeholder="Enter your hypothesis"
              variant="outlined"
              fullWidth
              multiline
              rows={6}
              value={ifWe}
              onChange={(e) => setIfWe(e.target.value)}
              sx={{
                mb: 2,
                "& .MuiInputBase-input::placeholder": {
                  color: "#a5d6a7", // Light mint green for placeholder text
                },
                "& .MuiInputBase-input": {
                  color: "#212529",
                  fontSize: "1.125rem",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#66bb6a", // Mint green for focus
                  },
                },
              }}
            />
            <Typography variant="h6" sx={{ fontSize: "1.125rem", mb: 1 }}>
              We Will:
            </Typography>
            <TextField
              id="wewill-field"
              placeholder="Enter your actions"
              variant="outlined"
              fullWidth
              multiline
              rows={5}
              value={weWill}
              onChange={(e) => setWeWill(e.target.value)}
              sx={{
                mb: 2,
                "& .MuiInputBase-input::placeholder": {
                  color: "#a5d6a7", // Light mint green for placeholder text
                },
                "& .MuiInputBase-input": {
                  color: "#212529",
                  fontSize: "1.125rem",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#66bb6a", // Mint green for focus
                  },
                },
              }}
            />
          </Box>
        </Box>
      </Container>

      <Container
        id="pdf-content-2"
        maxWidth="md"
        sx={{
          mt: 4,
          backgroundColor: "secondary.main",
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
          "@media (max-width:600px)": {
            maxWidth: "100%",
          },
        }}
      >
        <Box sx={{ paddingBottom: 2, paddingLeft: 2, paddingRight: 2 }}>
          <Box sx={{ height: "20px" }} />

          <Box component="form">
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                fontSize: "0.875rem",
                mb: 0,
                color: "#388e3c", // Darker mint green
              }}
            >
              Brainstorm | Cost Benefit | Small Scale | Solutions
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                fontSize: "0.875rem",
                mb: 0,
                color: "#388e3c", // Darker mint green
              }}
            >
              Store Condition | Voice of Customer | Sales | Gross Profit |
              Traffic Counts
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                fontSize: "0.875rem",
                mb: 0,
              }}
            >
              This is the implementation stage. It's how you make it happen, but
              on a smaller scale. List all the
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                fontSize: "0.875rem",
                mb: -5,
              }}
            >
              actions necessary to solve your problem. (EXECUTION)
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "1.5rem", mb: 1 }}>
              Do
            </Typography>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={9}>
                <Typography variant="h6" sx={{ fontSize: "1.125rem", mb: 1 }}>
                  What
                </Typography>
                {[0, 1, 2, 3, 4, 5, 6, 7].map((index) => (
                  <TextField
                    id={`dowhat-field-${index}`}
                    key={index}
                    placeholder="Enter what"
                    variant="outlined"
                    fullWidth
                    value={doWhat[index]}
                    onChange={(e) => handleInputChange(e, setDoWhat, index)}
                    inputProps={{ maxLength: 70 }}
                    sx={{
                      mb: 1,
                      "& .MuiInputBase-input::placeholder": {
                        color: "#a5d6a7", // Light mint green for placeholder text
                      },
                      "& .MuiInputBase-input": {
                        color: "#212529",
                        fontSize: "0.875rem",
                        padding: "0px 8px", // Adjust padding to reduce height
                        height: "1.2em", // Adjust height to fit text
                        lineHeight: "2.2em", // Ensure line height matches text height
                      },
                      "& .MuiOutlinedInput-root": {
                        "& .MuiOutlinedInput-inputMultiline": {
                          padding: "0px", // Adjust padding for multiline input
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#66bb6a", // Mint green for focus
                        },
                        padding: "0px", // Adjust padding to reduce height
                      },
                    }}
                    InputProps={{
                      style: {
                        height: "1.925rem", // Adjust height as needed
                        fontSize: "1.25rem", // Increase font size
                        // padding: "0 14px", // Adjust padding to center the text
                      },
                    }}
                  />
                ))}
              </Grid>
              <Grid item xs={1.5}>
                <Typography variant="h6" sx={{ fontSize: "1.125rem", mb: 1 }}>
                  Who
                </Typography>
                {[0, 1, 2, 3, 4, 5, 6, 7].map((index) => (
                  <TextField
                    id={`dowho-field-${index}`}
                    key={index}
                    placeholder="Who"
                    variant="outlined"
                    fullWidth
                    value={doWho[index]}
                    onChange={(e) => handleInputChange(e, setDoWho, index)}
                    inputProps={{ maxLength: 11 }}
                    sx={{
                      mb: 1,
                      "& .MuiInputBase-input::placeholder": {
                        color: "#a5d6a7", // Light mint green for placeholder text
                      },
                      "& .MuiInputBase-input": {
                        color: "#212529",
                        fontSize: "0.875rem",
                        padding: "0px 8px", // Adjust padding to reduce height
                        height: "1.2em", // Adjust height to fit text
                        lineHeight: "2.2em", // Ensure line height matches text height
                      },
                      "& .MuiOutlinedInput-root": {
                        "& .MuiOutlinedInput-inputMultiline": {
                          padding: "0px", // Adjust padding for multiline input
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#66bb6a", // Mint green for focus
                        },
                        padding: "0px", // Adjust padding to reduce height
                      },
                    }}
                    InputProps={{
                      style: {
                        height: "1.925rem", // Adjust height as needed
                        fontSize: "1.25rem", // Increase font size
                        // padding: "0 14px", // Adjust padding to center the text
                      },
                    }}
                  />
                ))}
              </Grid>
              <Grid item xs={1.5}>
                <Typography variant="h6" sx={{ fontSize: "1.125rem", mb: 1 }}>
                  When
                </Typography>
                {[0, 1, 2, 3, 4, 5, 6, 7].map((index) => (
                  <TextField
                    id={`dowhen-field-${index}`}
                    key={index}
                    placeholder="When"
                    variant="outlined"
                    fullWidth
                    value={doWhen[index]}
                    onChange={(e) => handleInputChange(e, setDoWhen, index)}
                    inputProps={{ maxLength: 11 }}
                    sx={{
                      mb: 1,
                      "& .MuiInputBase-input::placeholder": {
                        color: "#a5d6a7", // Light mint green for placeholder text
                      },
                      "& .MuiInputBase-input": {
                        color: "#212529",
                        fontSize: "0.875rem",
                        padding: "0px 8px", // Adjust padding to reduce height
                        height: "1.2em", // Adjust height to fit text
                        lineHeight: "2.2em", // Ensure line height matches text height
                      },
                      "& .MuiOutlinedInput-root": {
                        "& .MuiOutlinedInput-inputMultiline": {
                          padding: "0px", // Adjust padding for multiline input
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#66bb6a", // Mint green for focus
                        },
                        padding: "0px", // Adjust padding to reduce height
                      },
                    }}
                    InputProps={{
                      style: {
                        height: "1.925rem", // Adjust height as needed
                        fontSize: "1.25rem", // Increase font size
                        // padding: "0 14px", // Adjust padding to center the text
                      },
                    }}
                  />
                ))}
              </Grid>
            </Grid>

            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                fontSize: "0.875rem",
                mb: 0,
                color: "#388e3c", // Darker mint green
              }}
            >
              Results | Adjustments | Standardized
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                fontSize: "0.875rem",
                mb: 0,
                color: "#388e3c", // Darker mint green
              }}
            >
              Adjust and Repeat | Address the Issue | Eliminate the gap
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                fontSize: "0.875rem",
                mb: 0,
              }}
            >
              Check your results. Answer the following questions: "Did you reach
              your goal? Did you
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                fontSize: "0.875rem",
                mb: -4,
              }}
            >
              solve the problem? What did you learn? Did you prove your
              hypothesis? (Verification)"
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "1.5rem", mb: 1 }}>
              Check
            </Typography>
            <TextField
              id="check-field"
              placeholder="Enter how you will check"
              variant="outlined"
              fullWidth
              multiline
              rows={8}
              value={check}
              onChange={(e) => setCheck(e.target.value)}
              sx={{
                mb: 3,
                "& .MuiInputBase-input::placeholder": {
                  color: "#a5d6a7", // Light mint green for placeholder text
                },
                "& .MuiInputBase-input": {
                  color: "#212529",
                  fontSize: "1.125rem",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#66bb6a", // Mint green for focus
                  },
                },
              }}
            />
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                fontSize: "0.875rem",
                mb: 0,
                color: "#388e3c", // Darker mint green
              }}
            >
              Do It | Go Back to Plan
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                fontSize: "0.875rem",
                mb: 0,
                color: "#388e3c", // Darker mint green
              }}
            >
              Develop by-Store Action Plans | Build Infrastructure | Back to
              Basics | RI
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                fontSize: "0.875rem",
                mb: 0,
              }}
            >
              Decide what to do next. This will depend on the outcome of your
              CHECK. In this stage,
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                fontSize: "0.875rem",
                mb: 0,
              }}
            >
              you get to implement the suggested changes and standardize the
              process. If you didn't get
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                textAlign: "center",
                fontSize: "0.875rem",
                mb: -4,
              }}
            >
              the results you wanted as yourself why; this will lead you back to
              the PLAN stage.
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "1.5rem", mb: 1 }}>
              Act
            </Typography>
            <TextField
              id="act-field"
              placeholder="Enter how you will act"
              variant="outlined"
              fullWidth
              multiline
              rows={7}
              value={act}
              onChange={(e) => setAct(e.target.value)}
              sx={{
                mb: 2,
                "& .MuiInputBase-input::placeholder": {
                  color: "#a5d6a7", // Light mint green for placeholder text
                },
                "& .MuiInputBase-input": {
                  color: "#212529",
                  fontSize: "1.125rem",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#66bb6a", // Mint green for focus
                  },
                },
              }}
            />
          </Box>
        </Box>
      </Container>
      <Container
        id="pdf-generate-button"
        maxWidth="md"
        sx={{
          mt: 3,
          backgroundColor: "secondary.main",
          padding: 1,
          borderRadius: 2,
          boxShadow: 3,
          "@media (max-width:600px)": {
            maxWidth: "100%",
          },
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 1,
            backgroundColor: "#66bb6a", // Darker mint green
            color: "#fff", // White text
            width: "100%", // Reduced width to match the white section above
            mx: "auto", // Centered horizontally
            display: "block", // Center the button
            "&:hover": {
              backgroundColor: "#388e3c", // Mint green on hover
            },
          }}
          onClick={generatePDF}
        >
          Generate PDF
        </Button>
      </Container>
    </Container>
  );
};

export default PDCAForm;
